export const getCheckedOutlets = (personal, recommendedOutlets) => {
	let result = 0;
	if (personal?.distributions?.outlets) {
		const recommendedOutletsIDs = recommendedOutlets.map(({ id }) => id);
		result =
			personal.distributions.outlets &&
			typeof personal.distributions.outlets ===
				'string'
					.split(',')
					.filter((checkedOutlet) =>
						recommendedOutletsIDs.includes(+checkedOutlet)
					).length;
	}

	return result;
};
