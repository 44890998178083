// Core
import React, { useContext, useEffect, useState } from 'react';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from '../StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from '../StatisticCategory/utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../StatisticCategory/utils/getStartEndDatesForOneYear';

// Components
import ProductTypesGraph from './ProductTypesGraph/ProductTypesGraph';
import TopOutletsGraph from './TopOutletsGraph/TopOutletsGraph';
import TopOutletsGraphVis from './TopOutletsGraphVis/TopOutletsGraphVis';
import MuiMultipleCheckedOutlets from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';

// Styles
import styles from './Trends.module.css';
import StatisticFilterModalTrend from './StatisticFilterModalTrend/StatisticFilterModalTrend';
import '../StatisticCategory/StatisticByDatesSection/DatesBlockNew/DateBlockNewForDate.css';

import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';
import DatesBlockNew from '../StatisticCategory/StatisticByDatesSection/DatesBlockNew/DatesBlockNew';
import { Loading } from 'components';

const Trends = (props) => {
	const {
		rootContext: {
			getAccountStatisticTopOutlets,
			getAccountStatisticTypeProducts,
		},
		authContext: { accountId },
	} = props;

	let { pathname } = useLocation();
	const { lang } = useContext(LangContext);

	// Date Picker
	const { showModal } = useContext(UIContext);
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);

	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	//Filters Data
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);
	const [recordingsForRender, setRecordingsForRender] = useState([]);
	const [releasesForRender, setReleasesForRender] = useState([]);
	const [performersForRender, setPerformersForRender] = useState([]);
	const [countriesForRender, setCountriesForRender] = useState([]);
	const [hasRecordingsForRender, setHasRecordingsForRender] = useState(true);

	// Data requests
	const [topOutlets, setTopOutlets] = useState([]);
	const [loadingOutlets, setLoadingOutlets] = useState(true);
	const [typeProducts, setTypeProducts] = useState([]);
	const [loadingProducts, setLoadingProducts] = useState(true);
	const isLoading = loadingOutlets || loadingProducts;

	// Other
	const outletsColor = {
		apple: '#D60017', // цвета не из фигмы
		spotify: '#4BDF80',
		zvuk: '#4EA5F8', // цвета не из фигмы
		vk: '#3392FF',
		ok: '#FF6B18', // цвета не из фигмы
		yandex: '#FDD533',
		deezer: '#9C35F9', // цвета не из фигмы
		youtube: '#FF7E7E',
		tiktok: '#E374FD',
		facebook: '#1473B8', // цвета не из фигмы
		instagram: '#FF0A9D', // цвета не из фигмы
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
			setStatisticData({ ...statisticData });
		}
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
		console.log({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const getOutlets = () => {
		setLoadingOutlets(true);
		getAccountStatisticTopOutlets(
			false,
			accountId,
			outletCodes,
			statisticData.start_date,
			statisticData.end_date,
			releasesForRender,
			recordingsForRender,
			true
		).then((res) => {
			const data = res.data.data.data.map((item) => ({
				...item,
				angle: item.percentage,
				color: outletsColor[item.outlet],
			}));
			setTopOutlets(data);
			setLoadingOutlets(false);
		});
	};

	const getTypeProducts = () => {
		setLoadingProducts(true);
		getAccountStatisticTypeProducts(
			false,
			accountId,
			outletCodes,
			statisticData.start_date,
			statisticData.end_date,
			releasesForRender,
			recordingsForRender
		).then((res) => {
			setTypeProducts(res.data.data.data);
			setLoadingProducts(false);
		});
	};

	const startEndDates = getStartEndDatesForOneYear(statisticData, false);

	useEffect(() => {
		getOutlets();
		getTypeProducts();
	}, [
		statisticData,
		performersForRender,
		releasesForRender,
		recordingsForRender,
	]);

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	return (
		<div className={styles.container}>
			<div className={styles.wrapperFilters}>
				<div className={styles.inputOutlets}>
					<MuiMultipleCheckedOutlets
						handleCheckedOutlets={handleCheckedOutlets}
						lang={lang}
						className={styles.tracksCheckmarks}
					/>
				</div>
				<div className={styles.datePicker}>
					<DatesBlockNew
						accountId={accountId}
						// accountOutlets={accountOutlets}
						// isAdmin={isAdmin}
						changeField={changeField}
						statisticData={datePickerData}
						errors={errors}
						handleCheckedPeriod={handleCheckedPeriod}
						setApplyDate={setApplyDate}
						applyDateFilter={applyDateFilter}
						setIsLastCalendar={setIsLastCalendar}
					/>
				</div>
				<div
					onClick={() => setIsFilterModalActive(true)}
					className={styles.filtersButton}
				>
					<img src={filter_svg} alt="" />
					<span>
						<FormattedMessage id={'rod.admin.reports.filters'} />
					</span>
				</div>
			</div>
			{isLoading ? (
				<Loading className={styles.loader} />
			) : (
				<div>
					<div className={styles.graphsWrapper}>
						<ProductTypesGraph typeProducts={typeProducts} />
						<TopOutletsGraphVis outlets={topOutlets} />
					</div>
				</div>
			)}
			<StatisticFilterModalTrend
				// isAdmin={isAdmin}
				accountId={accountId}
				statisticData={statisticData}
				errors={errors}
				startEndDates={startEndDates}
				active={isFilterModalActive}
				// setLoading={setLoading}
				// handleResetAllFilters={handleResetAllFilters}
				setActive={() => setIsFilterModalActive(false)}
				// setActiveFilters={setActiveFilters}
				performersForRender={setPerformersForRender}
				releasesForRender={setReleasesForRender}
				recordingsForRender={setRecordingsForRender}
				countriesForRender={setCountriesForRender}
				setHasRecordingsForRender={setHasRecordingsForRender}
				lang={lang}
				// setPage={setPage}
				handleSetFilters={(filtersData, isAllChecked, searchValue) => {
					Object.keys(filtersData).forEach((filterKey) => {
						if (filtersData[filterKey]) {
							statisticData[filterKey] = filtersData[filterKey];
						}
					});
					if (isAllChecked) {
						statisticData.tracksIds = [];
					}
					if (searchValue) {
						statisticData.trackSearchValue = searchValue;
					}

					statisticData.checkedAllTracks = isAllChecked;
					statisticData.page = 1;
					setStatisticData({ ...statisticData });
				}}
			/>
		</div>
	);
};

export default compose(withUI, withAuth, withRoot)(Trends);
