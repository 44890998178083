// Core
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import TimePicker from 'components/Inputs/TimePicker/TimePicker';

// Styles
import styles from './DateTimeBlock.module.css';

const DateTimeBlock = ({
	title,
	name,
	data,
	changeField,
	setTimeInHHMM,
	initialTimeInHHMM,
	errors,
	itemValue,
}) => {
	// eslint-disable-next-line no-unused-vars
	const [isSelectedTimeValid, setIsSelectedTimeValid] = useState(true);

	const handleIsSelectedTimeValid = (data) => {
		setIsSelectedTimeValid(data);
	};

	return (
		<div>
			<div className={styles.title}>
				<FormattedMessage id={title} /> <span>*</span>
			</div>
			<div className={styles.dateContainer}>
				<div className={styles.datePicker}>
					<FormattedMessage
						id={'rod.distribution.date.sale_start_date.picker.label'}
					>
						{(placeholder) => (
							<FormInput
								type={'datePickerForAssets'}
								placeholder={placeholder}
								name={name}
								onChange={changeField}
								errors={errors}
								data={data}
								floatingLabel
								minDate={
									itemValue === '7+'
										? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
										: new Date(Date.now() + 24 * 60 * 60 * 1000)
								}
							/>
						)}
					</FormattedMessage>
				</div>
				<div>
					<TimePicker
						timezone
						setTimeInHHMM={setTimeInHHMM}
						initialTimeInHHMM={initialTimeInHHMM}
						handleIsSelectedTimeValid={handleIsSelectedTimeValid}
					/>
				</div>
			</div>
		</div>
	);
};

export default DateTimeBlock;
