// Core
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';

// UI
import { Label } from 'components/Labels';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from './MuiMultipleCheckedDistributionOutlets.module.css';

const MuiMultipleCheckedDistributionOutlets = (props) => {
	const [outlets, setOutlets] = useState(props.outlets);
	const [selectedData, setSelectedData] = useState([]);

	useEffect(() => {
		const selectedOutlets = props.outlets.filter((item) => item.checked);
		setSelectedData(selectedOutlets);
		props.handleCheckedOutlets(selectedOutlets);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheсked = (outlet) => {
		const data = outlets.map((item) => {
			if (item.id === outlet.id) {
				return { ...item, checked: !item.checked };
			}
			return item;
		});

		setOutlets(data);
		const selectedArray = data.filter((item) => item.checked);
		setSelectedData(selectedArray);
		props.handleCheckedOutlets(selectedArray);
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1 }}
				className={s.formControl}
				error={!selectedData.length}
			>
				<Input id="demo-multiple-checkbox-label">
					<FormattedMessage
						id={'rod.distribution.outlet.modal.outlets.select.title'}
					/>
				</Input>
				<SelectCustom
					multiple
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					disabled={false}
					MenuProps={{
						style: { zIndex: 13000000000 },
					}}
					value={selectedData}
					renderValue={(selected) => {
						if (selected.length === 1) {
							return <p>{selected[0].title}</p>;
						}

						return (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length}/{outlets.length}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						);
					}}
					className={s.selectCustom}
				>
					<div className={s.wrapper}>
						{outlets.length > 0 ? (
							outlets.map((outlet, index) => (
								<MenuItem
									className={s.menuItem}
									key={index}
									onClick={(e) => {
										if (e.target.tagName !== 'svg') {
											handleCheсked(outlet);
										}
									}}
									value={outlet.raw_title}
								>
									<CheckboxBlack
										checked={outlet.checked}
										onChange={() => handleCheсked(outlet)}
									/>
									<Label>{outlet.title}</Label>
								</MenuItem>
							))
						) : (
							<Label font="--gilroy-Medium-18" className={s.noData}>
								<FormattedMessage id={'rod.no_data'} />
							</Label>
						)}
					</div>
				</SelectCustom>
			</FormControl>
			{!selectedData.length && (
				<span className={s.error}>
					{
						<FormattedMessage
							id={'rod.distribution.outlet.modal.outlets.select.error'}
						/>
					}
				</span>
			)}
			{selectedData.length === outlets.length && (
				<span className={s.error}>
					{
						<FormattedMessage
							id={'rod.distribution.outlet.modal.outlets.select.error.all'}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default compose(
	withRoot,
	withAuth
)(MuiMultipleCheckedDistributionOutlets);
