// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { RadioGroup } from 'components/Radio';
import { BottomNavi, InfoHelper, Loading } from 'components';
import OutletsBlock from './OutletsBlock/OutletsBlock';

// Utils
import { correctDate } from './utils/correctDate';
import { radioDate, radioDateSla } from './utils/radio';
import { isDateInPast } from './utils/isDateInPast';

// Styles
import s from './Distribution.module.css';

const Distribution = (props) => {
	const { isSlaPrivelegesForAccount, isTimeZoneForAccount } = useContext(
		AuthContext
	);
	const { lang } = useContext(LangContext);
	const { setIsBottomNaviShow } = useContext(UIContext);
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [personalData, setPersonalData] = useState('7-');
	const [personalData2, setPersonalData2] = useState('all');
	const [inputDate, setInputDate] = useState(false);
	const [inputTransferDate, setInputTransferDate] = useState(false);
	const [isDateEmpty, setIsDateEmpty] = useState(false);
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [allChecked, setAllChecked] = useState(true);
	const [checks, setChecks] = useState([]);
	const [checked, setChecked] = useState([]);
	const [personal, setPersonal] = useState(
		props.data || {
			distribution_date: '7-',
			outlets: 'All outlets available',
		}
	);
	const [errors, setErrors] = useState(props.errors ?? {});
	const [transferRelease] = useState(props.transferRelease);
	const [distributionData, setDistributionData] = useState();
	const [disableNextBtn, setDisabledNextBtn] = useState(true);
	const [showRequiredDateError, setShowRequiredDateError] = useState(false);
	const [h11Moderation, setH11Moderation] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);
	const [selectOutletsDateCheckbox, setSelectOutletsDateCheckbox] = useState(
		false
	);
	const [outletsList, setOutletsList] = useState([]);

	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));

	const filterOutlets = (outletsToFilter) => {
		if (isTimeZoneForAccount && personal.distribution_outlets?.length) {
			const uniqOutlets = outletsToFilter.filter(
				(item) => !personal.distribution_outlets[0].outlets.includes(item.id)
			);
			return uniqOutlets;
		} else {
			return outletsToFilter;
		}
	};
	const h11EditId = releaseEdit?.id;

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDisabledNextBtn(
			(!allChecked && Object.keys(errors).includes('check_box_outlets')) ||
				Object.keys(errors).includes('sale_start_date') ||
				isLoading ||
				(personal?.type !== 'asap' && !personal?.sale_start_date) ||
				(personal?.type === 'regular' &&
					isTimeZoneForAccount &&
					!personal.delivery_start_time)
		);

		if (personal?.type !== 'asap' && !personal?.sale_start_date) {
			setShowRequiredDateError(true);
		} else {
			setShowRequiredDateError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allChecked, errors, isLoading, personal]);

	if (isH11Edit && h11EditId) {
		accounts
			.getReleaseData(h11EditId)
			.then((res) => {
				const step = res.data.data?.step;
				if (step === 'h11_moderation') {
					setH11Moderation(true);
				} else {
					setH11Moderation(false);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});
	}

	useEffect(() => {
		accounts
			.getDistribution(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				res = res.data.data;
				if (res.sale_start_date) {
					const e = {};
					e.currentTarget = { value: getType(res.type) };
					personal.sale_start_date = res.sale_start_date.slice(0, 10);
					distributionDateRadioHandler(e);
					if (isH11Edit && res.type === 'update') {
						personal.type = 'update';
						setInputDate(true);
					}
				}

				if (!res.outlets) {
					accounts
						.getOutletsWithTypes(lang, props.releaseTypeId)
						.then((outlets) => {
							outlets = outlets.data.data;
							setOutletsList(outlets);

							const e = {};
							e.currentTarget = { value: getType(res?.type || 'asap') };
							distributionDateRadioHandler(e);
							for (let i = 0; i < outlets.length; i++) {
								outlets[i].checked = true;
							}
							if (isH11Edit) {
								setPersonalData2('inc');
								setShowCheckbox(true);
							}
							const notCheckedOutlets = outlets.map((outlet) => {
								return { ...outlet, checked: false };
							});
							setChecks(isH11Edit ? notCheckedOutlets : outlets);
							setChecked(outlets);
						});
				} else {
					accounts
						.getOutletsWithTypes(lang, props.releaseTypeId)
						.then((outlets) => {
							outlets = outlets.data.data;
							setOutletsList(outlets);

							const data = res.outlets?.split(',');
							const isAllOutlets = data.length === outlets.length;
							let e = {};
							e.currentTarget = { value: isAllOutlets ? 'all' : 'inc' };
							outletsRadioHandler(e);
							setAllChecked(isAllOutlets);
							setShowCheckbox(!isAllOutlets);
							const checkedArr = [];
							for (let i = 0; i < outlets.length; i++) {
								if (
									data.includes(outlets[i].id.toString()) ||
									outlets[i].is_recommended === 1
								) {
									outlets[i].checked = true;
									checkedArr.push(outlets[i]);
								} else {
									outlets[i].checked = false;
								}
							}
							setChecks(outlets);
							setChecked(checkedArr);
						});
				}
				setDistributionData(res);
				setPersonal(res);

				if (isTimeZoneForAccount && res.distribution_outlets?.length > 0) {
					setSelectOutletsDateCheckbox(true);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});

		if (!distributionData?.outlets) {
			accounts
				.getOutletsWithTypes(lang, props.releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;
					setOutletsList(outlets);

					for (let i = 0; i < outlets.length; i++) {
						outlets[i].checked = true;
					}
					setChecks(outlets);
					setChecked(outlets);
				})
				.catch((error) => {
					console.error('Error', error);
				})
				.finally(() =>
					setTimeout(() => {
						setIsLoading(false);
					}, 1000)
				);
		} else {
			accounts
				.getOutletsWithTypes(lang, props.releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;
					setOutletsList(outlets);

					const data = personal.outlets;
					const checkedArr = [];
					for (let i = 0; i < outlets.length; i++) {
						if (
							data.includes(outlets[i].id) ||
							outlets[i].is_recommended === 1
						) {
							outlets[i].checked = true;
							checkedArr.push(outlets[i]);
						} else {
							outlets[i].checked = false;
						}
					}
					setChecks(outlets);
					setChecked(checkedArr);
				})
				.catch((error) => {
					console.error('Error', error);
				})
				.finally(() =>
					setTimeout(() => {
						setIsLoading(false);
					}, 1000)
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		if (Object.keys(props.errors).length > 0 && props.errors.sale_start_date) {
			if (
				props.errors.sale_start_date[0]?.rule ===
				'enter the original sales start date (must be in the past)'
			) {
				setErrors({
					...errors,
					sale_start_date: [{ rule: 'maxDate' }],
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	useEffect(() => {
		if (
			checked.length - checks.filter((item) => item.is_recommended).length ===
				0 &&
			!allChecked
		) {
			setErrors((prev) => ({
				...prev,
				check_box_outlets: [{ rule: 'check_box_outlets_required' }],
			}));
		} else if (errors.check_box_outlets) {
			setErrors((prev) => {
				let obj = { ...prev };
				delete obj.check_box_outlets;
				return obj;
			});
		}
		// eslint-disable-next-line
	}, [checked, allChecked]);

	const getType = (type) => {
		if (type === 'transfer') return '1-';
		if (type === 'regular') {
			if (isSlaPrivelegesForAccount) {
				return '1+';
			} else {
				return '7+';
			}
		}
		if (type === 'asap') return '7-';
	};

	const errorsFromInput = (err) => {
		if (!Object.keys(err).length) {
			setErrors((prev) => {
				let obj = { ...prev };
				delete obj?.sale_start_date;
				return obj;
			});
		} else {
			setErrors((prev) => ({ ...prev, ...err }));
		}
	};

	const clearIsDateEmpty = () => {
		setIsDateEmpty(false);
	};

	const nextHandler = () => {
		if (inputTransferDate && personal.sale_start_date === '') {
			return setIsDateEmpty(true);
		}

		setDisableBtn(true);

		if (
			typeof props.onChange === 'function' &&
			Object.keys(errors).length === 0
		) {
			allChecked
				? (personal.outlets = filterOutlets(checks).map((item) => item.id))
				: (personal.outlets = filterOutlets(checked).map((item) => item.id));

			if (
				personal.sale_start_date &&
				!isTimeZoneForAccount &&
				correctDate(personal.sale_start_date).includes('NaN')
			) {
				setErrors({ ...errors, sale_start_date: [{ rule: 'wrong_format' }] });
				setDisableBtn(false);
			} else {
				if (
					personal.distribution_outlets &&
					personal.distribution_outlets[0].outlets?.length &&
					typeof personal.distribution_outlets[0].outlets === 'string'
				) {
					personal.distribution_outlets[0].outlets = personal.distribution_outlets[0].outlets.split(
						','
					);
				}
				try {
					props
						.onChange({
							...personal,
							distribution_outlets:
								!isTimeZoneForAccount || personal.type === 'asap'
									? null
									: personal.distribution_outlets,
							delivery_start_time:
								!isTimeZoneForAccount || personal.type === 'asap'
									? null
									: personal.delivery_start_time,
							sale_start_date:
								isTimeZoneForAccount && personal.type === 'regular'
									? personal.sale_start_date
									: correctDate(
											personal.type === 'asap'
												? correctDate(Date.now() + 172800000)
												: personal.sale_start_date
									  ),
						})
						.then()
						.catch((error) => setErrors(error.response.data.errors))
						.finally(() => setDisableBtn(false));
				} catch (error) {
					console.error(error.response);
					setDisableBtn(false);
				}
			}
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/cover`);
			} else {
				history.push(`/release/${releaseId}/cover`);
			}
			props.handlePrevStep();
		}
	};

	const inputChangeField = (data) => {
		setPersonal({ ...data });
	};

	const distributionDateRadioHandler = (e) => {
		if (e.currentTarget.value === '7+') {
			const shouldUpdateDate = isDateInPast(personal.sale_start_date, 7);
			setPersonal((prev) => ({
				...prev,
				sale_start_date:
					personal.sale_start_date && !shouldUpdateDate
						? personal.sale_start_date
						: correctDate(Date.now() + 604800000),
				type: 'regular',
			}));
			setInputTransferDate(false);
			setInputDate(true);
		} else if (e.currentTarget.value === '1+') {
			const shouldUpdateDate = isDateInPast(personal.sale_start_date, 1);
			setPersonal((prev) => ({
				...prev,
				sale_start_date:
					personal.sale_start_date && !shouldUpdateDate
						? personal.sale_start_date
						: correctDate(Date.now() + 86400000),
				type: 'regular',
			}));
			setInputTransferDate(false);
			setInputDate(true);
		} else if (e.currentTarget.value === '1-') {
			setPersonal((prev) => ({
				...prev,
				sale_start_date: '',
				type: 'transfer',
			}));
			setInputDate(false);
			setInputTransferDate(true);
		} else if (e.currentTarget.value === '7-') {
			setInputDate(false);
			setInputTransferDate(false);
			setPersonal((prev) => ({
				...prev,
				type: props.transferRelease ? 'transfer' : 'asap',
			}));
			if (props.transferRelease && !personal.sale_start_date) {
				setPersonal((prev) => ({
					...prev,
					sale_start_date: correctDate(Date.now() - 3 * 24 * 60 * 60 * 1000),
				}));
			}
		}
		setPersonalData(e.currentTarget.value);
	};

	const outletsRadioHandler = (e) => {
		setPersonalData2(e.currentTarget.value);
		const checkedId = checked.map((item) => item.id);
		const allOutletsId = checks.map((item) => item.id);
		personal.outlets =
			e.currentTarget.value === 'all' ? allOutletsId : checkedId;

		if (e.currentTarget.value === 'all') {
			setAllChecked(true);
			setShowCheckbox(false);
		} else {
			setAllChecked(false);
			setShowCheckbox(true);
		}
	};

	const getCheckedOutlets = (item) => {
		switch (item.checked) {
			case true:
				const youtubeOutlet = checks.find((outlet) => outlet.id === 21859);
				if (item.id === 436356 && !youtubeOutlet.checked) {
					youtubeOutlet['checked'] = true;
					setChecked((prev) => [...prev, item, youtubeOutlet]);
				} else setChecked((prev) => [...prev, item]);

				break;
			case false:
				const youtubeIdOutlet = checks.find((outlet) => outlet.id === 436356);
				if (item.id === 21859 && youtubeIdOutlet.checked) {
					youtubeIdOutlet['checked'] = false;
					const data = checked.filter(
						(i) => i.id !== item.id && i.id !== 436356
					);
					setChecked(data);
				} else {
					const data = checked.filter((i) => i.id !== item.id);
					setChecked(data);
				}
				break;
			default:
				break;
		}
	};

	const changeSelectDateOutletsCheckbox = () => {
		setSelectOutletsDateCheckbox(!selectOutletsDateCheckbox);
	};

	return (
		<>
			<div className={s.page}>
				{isLoading && <Loading className={s.loader} />}
				{!isLoading && (
					<>
						<div className={s.main}>
							<span className={s.title}>
								<FormattedMessage id={'rod.release.create.step.distribution'} />
							</span>
							<div className={s.body}>
								<div className={s.Input__radio}>
									<div className={s.Input__title}>
										<FormattedMessage
											id={'rod.release.create.step.submit.release_date'}
										/>
									</div>
									<RadioGroup
										className={s.RadioGroup_date}
										name={'distribution_date'}
										value={personalData}
										onChange={distributionDateRadioHandler}
										items={isSlaPrivelegesForAccount ? radioDateSla : radioDate}
										personal={personal}
										setPersonal={setPersonal}
										inputDate={inputDate}
										inputTransferDate={inputTransferDate}
										inputChangeField={inputChangeField}
										errors={errors}
										errorsFromInput={errorsFromInput}
										isDateEmpty={isDateEmpty}
										clearIsDateEmpty={clearIsDateEmpty}
										transferRelease={transferRelease}
										isH11Edit={isH11Edit}
										showRequiredDateError={showRequiredDateError}
										h11Moderation={h11Moderation}
										isSlaPrivelegesForAccount={isSlaPrivelegesForAccount}
										lang={lang}
										isTimeZoneForAccount={isTimeZoneForAccount}
										selectOutletsDateCheckbox={selectOutletsDateCheckbox}
										changeSelectDateOutletsCheckbox={
											changeSelectDateOutletsCheckbox
										}
										outlets={checks}
										outletsList={outletsList}
									/>
								</div>

								<OutletsBlock
									isH11Edit={isH11Edit}
									personalData2={personalData2}
									outletsRadioHandler={outletsRadioHandler}
									isLoading={isLoading}
									showCheckbox={showCheckbox}
									checks={checks}
									setChecks={setChecks}
									allChecked={allChecked}
									getCheckedOutlets={getCheckedOutlets}
									personal={personal}
								/>
							</div>
						</div>
						<InfoHelper text="rod.release.create.step.distributions.description" />
					</>
				)}
			</div>
			<div className={s.bottom_container}>
				<BottomNavi
					showPrevBtn
					disabled={disableNextBtn || disableBtn}
					back={backHandler}
					next={nextHandler}
				/>
				{Object.keys(errors).includes('check_box_outlets') && (
					<div className={s.error_container}>
						<span className={s.error}>
							{
								<FormattedMessage
									id={`rod.error.${errors.check_box_outlets[0].rule}`}
								/>
							}
						</span>
					</div>
				)}
			</div>
		</>
	);
};

export default Distribution;
