// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import { withUI, withAuth, withRoot } from 'hocs';

// Context
import { UIContext } from 'contexts/UIContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { checkRangeInOneYear } from '../utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../utils/getStartEndDatesForOneYear';
import { getDateInYYYYMMDD } from '../StatisticByDatesSection/utils/getDateInYYYYMMDD';

// Constants
import { TIME_CONSTANT } from 'constants/timeConstants';

// UI
import StatisticHeader from 'material-design/Statistic/StatisticHeader/StatisticHeader';
import { Loading } from 'components';
import StatisticFilterModal from '../StatisticFilterModal/StatisticFilterModal';
import NoDataStat from 'components/NoDataStats/NoDataStats';
import { FullScreenModalConfirm } from 'containers/Modal';
import DataLoadingError from '../DataLoadingError/DataLoadingError';
import TopTracksCombined from './TopTracksCombined/TopTracksCombined';
import InputsWrapper from './InputsWrapper/InputsWrapper';
import ColumnarBar from './ColumnarBar/ColumnarBar';
import { StatisticByTerritoriesChart } from './StatisticByTerritoriesChart';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticByTerritoriesSectionv2.module.css';
import '../StatisticByDatesSection/DatesBlockNew/DateBlockNewForDate.css';

const StatisticByTerritoriesSectionCombined = (props) => {
	let { pathname } = useLocation();
	const { lang } = useContext(LangContext);
	const {
		getAccountStatisticOutlets,
		getAccountStatisticTopTracks,
		getAccountStatisticAuthorTopTracks,
		getAccountStatisticsColumnar,
		getAccountStatisticsAuthorColumnar,
		getAccountStatisticCheck,
		getAccountStatisticAuthorMapByDate,
		getAccountStatisticMapByDate,
	} = useContext(RootContext);
	const {
		showModal,
		isCopyrightingStatisticsEnabled,
		isStatisticsColumnarCountries,
		isStatisticsMapCountries,
	} = useContext(UIContext);
	const chartRef = useRef(null);
	const id = localStorage.getItem('accountId');

	const [initialLoading, setInitialLoading] = useState(true);
	const [helper, setHelper] = useState(
		localStorage.getItem('helper') !== 'showed'
	);
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - TIME_CONSTANT * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - TIME_CONSTANT * 2)
			.toISOString()
			.split('T')[0],
		tracksIds: [],
		outlets: [],
		countries: [],
		per_page: 10,
		sort: 'desc',
		page: 1,
		checkedAllTracks: false,
		trackSearchValue: '',
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [total, setTotal] = useState(0);
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);
	const [mapTotalPages, setMapTotalPages] = useState(0);
	const [amountTitle, setAmountTitle] = useState('');
	const [topTracks, setTopTracks] = useState([]);
	const [outletCodes, setOutletCodes] = useState([]);
	const [countries, setCountries] = useState([]);
	const [initialOutlets, setInitialOutlets] = useState([]);
	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);
	const [errors, setErrors] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [isInitialData, setIsInitialData] = useState(true);
	const [columnarMap, setColumnarMap] = useState([]);
	const [accountMap, setAccountMap] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [mapDataError, setMapDataError] = useState(false);
	const [chartMode, setChartMode] = useState(props.chartMode ?? 'phono');
	const [showHeading, setShowHeading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [showContent, setShowContent] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [lastStatDate, setLastStatDate] = useState(null);
	const [applyDate, setApplyDate] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [outletsFetched, setOutletsFetched] = useState(false);

	const { adminAccountId } = props;

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	const mapRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorMapByDate
			: getAccountStatisticMapByDate;

	const columnarRequest =
		chartMode === 'compo'
			? getAccountStatisticsAuthorColumnar
			: getAccountStatisticsColumnar;

	const topTracksRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorTopTracks
			: getAccountStatisticTopTracks;

	const fetchStatisticsData = async () => {
		setInitialLoading(true);

		try {
			const outletsRes = await getAccountStatisticOutlets();
			const accountOutlets = outletsRes.data.data;
			setAccountOutlets(accountOutlets);
			setOutletsFetched(true);

			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = accountOutlets.map((item) => item.code);

			// Prepare an array of promises based on which statistics are required
			let requests = [];

			if (isStatisticsColumnarCountries && accountOutlets.length) {
				const columnarPromise = columnarRequest(
					accountId,
					statisticData.outlets.map((item) => item.code),
					startEndDates[0],
					startEndDates[1],
					statisticData.tracksIds,
					statisticData.per_page,
					statisticData.page,
					statisticData.sort,
					statisticData.countries.map((item) => item.code)
				).then(({ data }) => {
					const responseData = data.data;
					setApplyDate(false);
					setMapTotalPages(responseData.last_page * statisticData.per_page);
					setColumnarMap(responseData.global);

					if (responseData.global?.length) {
						let maxAuditions = Number(responseData.global[0].stat_count);
						if (
							statisticData.sort !== 'desc' &&
							responseData.global[responseData.global.length - 1]
						) {
							maxAuditions = Number(
								responseData.global[responseData.global.length - 1].stat_count
							);
						}
						if (maxAuditions <= 10000) {
							setAmountTitle('rod.statistic.chart-amount-units');
						} else if (maxAuditions > 10000) {
							setAmountTitle('rod.statistic.chart-amount-thousands');
						}
					}
				});
				requests.push(columnarPromise);
			}

			if (isStatisticsMapCountries && accountOutlets.length) {
				const mapPromise = mapRequest(
					isAdmin,
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					statisticData.tracksIds
				).then((res) => {
					const responseData = res.data.data;
					setAccountMap(responseData.global);
				});
				requests.push(mapPromise);
			}

			await Promise.all(requests);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setInitialLoading(false);
		}
	};

	useEffect(() => {
		fetchStatisticsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		statisticData.start_date,
		statisticData.end_date,
		statisticData.tracksIds,
		statisticData.outlets,
		statisticData.countries,
		statisticData.per_page,
		statisticData.sort,
		statisticData.page,
		statisticData.checkedAllTracks,
		chartMode,
	]);

	useEffect(() => {
		if (
			outletsFetched &&
			accountOutlets.length &&
			(tracksIds.length || statisticData.countries.length) &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = outlets.map((outlet) => outlet.code);

			//Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${isAllTracks ? 'all' : tracksIds.join('*')}*topTracks*${accountId}`;

			setPage(1);
			setIsPaginationPressed(false);
			const countriesList = statisticData.countries.map((item) => item.code);
			topTracksRequest(
				isAdmin,
				accountId,
				page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				[],
				countriesList
			).then((res) => {
				res = res.data.data;
				setApplyDate(false);
				setTopTracks(res.recordings);
				setTotal(res.total);
				setPageLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountOutlets, statisticData, tracksIds, chartMode]);

	useEffect(() => {
		if (
			outletsFetched &&
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const countriesList = statisticData.countries.map((item) => item.code);

			getAccountStatisticTopTracks(
				isAdmin,
				accountId,
				page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				statisticData.countries.map((item) => item.code),
				[],
				countriesList
			).then((res) => {
				res = res.data.data;
				setTopTracks(res.recordings);
				setTotal(res.total);
				setPageLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	const closeModal = () => {
		setShowRangeModal(false);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'per_page': {
				statisticData[field] = e.id;
				statisticData.page = 1;
				setStatisticData({ ...statisticData });
				chartRef.current.scrollIntoView({ behavior: 'smooth' });
				break;
			}
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
		if (statisticData['outlets'].length > 0 && isInitialData) {
			setIsInitialData(false);
		} else if (statisticData['outlets'].length === 0 && !isInitialData) {
			setIsInitialData(true);
		}
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (
			(tracks.length !== tracksIds.length && tracks.length !== 0) ||
			tracks.length === 1
		) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
		}
	};

	const handleCheckedOutlets = (outlets, initial = false) => {
		const codes = outlets.map((item) => item.code);

		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
		if (initial) {
			setInitialOutlets(outlets);
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
			statisticData.tracksIds = [track.id];
			statisticData.checkedAllTracks = false;
			setStatisticData({ ...statisticData, page: 1 });
			setPage(1);
		} else {
			setCkeckedTopTrack(null);
			statisticData.tracksIds = [];
			setStatisticData({ ...statisticData });
		}
	};

	const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	const isFiltersActive = () => {
		if (
			statisticData.outlets.length !== outletCodes.length ||
			statisticData.tracksIds.length ||
			statisticData.countries.length
		) {
			return true;
		}
		return false;
	};

	const setRouteData = async () => {
		try {
			const { data: res } = await getAccountStatisticCheck(id);
			const { statistic_types } = res;
			if (!statistic_types.length || statistic_types.length === 2) {
				setShowHeading(true);
			} else {
				setShowHeading(false);
			}

			if (!statistic_types.length) {
				setShowContent(false);
			} else {
				setShowContent(true);
			}
		} catch (error) {
			console.error('Error fetching account statistics:', error);
		}
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setPage(1);
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	useEffect(() => {
		const fetchRouteData = async () => {
			try {
				await setRouteData();
			} catch (error) {
				console.error('Error fetching account statistics:', error);
			}
		};
		if (id) {
			fetchRouteData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}

			{showHeading && isCopyrightingStatisticsEnabled && (
				<StatisticHeader
					chartMode={chartMode}
					setChartMode={setChartMode}
					accountId={accountId}
					statisticData={statisticData}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					trackSearchValue={statisticData.trackSearchValue}
					tracksIds={statisticData.tracksIds}
					isAllTracks={statisticData.checkedAllTracks}
					statType="byterritory"
					v2
				/>
			)}

			<div className={styles.statisticByTerritories__mainContent}>
				<InputsWrapper
					changeField={changeField}
					errors={errors}
					statisticData={statisticData}
					setStatisticData={setStatisticData}
					setIsFilterModalActive={setIsFilterModalActive}
					isFiltersActive={isFiltersActive}
					isAdmin={isAdmin}
					isCopyrightingStatisticsEnabled={isCopyrightingStatisticsEnabled}
					accountId={accountId}
					accountOutlets={accountOutlets}
					datePickerData={datePickerData}
					tracksIds={tracksIds}
					isAllTracks={isAllTracks}
					releaseId={props.releaseId}
					lastStatDate={lastStatDate}
					handleCheckedPeriod={handleCheckedPeriod}
					setApplyDate={setApplyDate}
					applyDateFilter={applyDateFilter}
					setIsLastCalendar={setIsLastCalendar}
					showHeading={showHeading}
				/>

				{initialLoading && <Loading className={styles.loader} />}
				{!initialLoading && mapDataError && <DataLoadingError />}
				{!initialLoading && !accountMap.length && !columnarMap.length && (
					<NoDataStat />
				)}

				{!initialLoading && (
					<>
						{columnarMap.length > 0 && isStatisticsColumnarCountries && (
							<ColumnarBar
								chartRef={chartRef}
								amountTitle={amountTitle}
								statisticData={statisticData}
								setStatisticData={setStatisticData}
								countries={countries}
								startEndDates={startEndDates}
								mapTotalPages={mapTotalPages}
								changeField={changeField}
								errors={errors}
								accountMap={columnarMap}
								setIsPaginationPressed={setIsPaginationPressed}
							/>
						)}

						{accountMap.length > 0 && isStatisticsMapCountries && (
							<StatisticByTerritoriesChart
								data={accountMap}
								selectedOutlets={
									isInitialData ? accountOutlets : statisticData.outlets
								}
								sort={statisticData.sort}
							/>
						)}

						{topTracks?.length > 0 && (
							<TopTracksCombined
								pageLoading={pageLoading}
								topTracks={topTracks}
								handleCheckedTopTrack={handleCheckedTopTrack}
								page={page}
								setPage={setPage}
								setIsPaginationPressed={setIsPaginationPressed}
								setPageLoading={setPageLoading}
								chartMode={chartMode}
								statisticData={statisticData}
								isAdmin={isAdmin}
								accountOutlets={accountOutlets}
								accountId={accountId}
								total={total}
							/>
						)}
					</>
				)}

				{showRangeModal && !props.main && (
					<FullScreenModalConfirm
						title={<FormattedMessage id={'rod.modal.statistic_title'} />}
						text={<FormattedMessage id={'rod.modal.statistic_text'} />}
						confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
						onClose={closeModal}
					/>
				)}
				<StatisticFilterModal
					lang={lang}
					isAdmin={isAdmin}
					accountId={accountId}
					statisticData={statisticData}
					errors={errors}
					startEndDates={startEndDates}
					active={isFilterModalActive}
					setLoading={setInitialLoading}
					setActive={() => setIsFilterModalActive(false)}
					checkedTopTrack={checkedTopTrack}
					setInitialData={(tracks, outlets, countries) => {
						handleCheckedTracks(tracks, true);
						handleCheckedOutlets(outlets, true);
						setCountries(countries);
					}}
					handleSetFilters={(filtersData, isAllChecked, searchValue) => {
						Object.keys(filtersData).forEach((filterKey) => {
							if (filtersData[filterKey]) {
								statisticData[filterKey] = filtersData[filterKey];
							}
						});
						if (isAllChecked) {
							statisticData.tracksIds = [];
						}
						if (searchValue) {
							statisticData.trackSearchValue = searchValue;
						}

						statisticData.checkedAllTracks = isAllChecked;
						statisticData.page = 1;
						setStatisticData({ ...statisticData });
					}}
					handleClearAllFilters={() => {
						setStatisticData({
							...statisticData,
							outlets: initialOutlets,
							tracksIds: [],
							countries: [],
							page: 1,
						});
					}}
					chartMode={chartMode}
				/>
			</div>
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(StatisticByTerritoriesSectionCombined);
