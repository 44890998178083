// Core
import React from 'react';
import { RadialChart, LabelSeries } from 'react-vis';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './ProductTypesGraph.module.css';

const ProductTypesGraph = ({ typeProducts }) => {
	const data = [
		{
			angle: typeProducts.streams,
			label: `${typeProducts.streams}%`,
			color: '#F0F0F0',
		},
		{
			angle: typeProducts.downloads,
			label: `${typeProducts.downloads}%`,
			color: '#DADE67',
		},
	];

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.radial-chart.title'} />
			</h2>
			<div className={styles.graph}>
				<RadialChart
					data={data}
					width={227}
					height={227}
					colorType="literal"
					radius={100}
					showLabels={true}
					labelsRadiusMultiplier={0.7}
					labelsStyle={{
						fill: '#1E1E1E4D',
						fontSize: '16px',
						fontWeight: 'bold',
					}}
				></RadialChart>
				<div className={styles.labels}>
					<div className={styles.labelListening}>
						<div className={styles.leftSideLabel}>
							<span
								className={styles.labelColorLine}
								style={{ backgroundColor: '#D9D9D9' }}
							></span>
							<p className={styles.labelPercent}>{typeProducts.streams}%</p>
						</div>

						<span className={styles.labelText}>
							<FormattedMessage
								id={'rod.statistic.trends.radial-chart.streams'}
							/>
						</span>
					</div>
					<div className={styles.labelDownloads}>
						<div className={styles.leftSideLabel}>
							<span
								className={styles.labelColorLine}
								style={{ backgroundColor: '#DADE67' }}
							></span>
							<p className={styles.labelPercent}>{typeProducts.downloads}%</p>
						</div>
						<span className={styles.labelText}>
							<FormattedMessage
								id={'rod.statistic.trends.radial-chart.downloads'}
							/>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductTypesGraph;
