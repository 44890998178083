// Core
import React, { useState, useEffect, useRef } from 'react';
import { RadialChart } from 'react-vis';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './TopOutletsGraph.module.css';

const codeFormatted = {
	apple: 'Apple Music',
	spotify: 'Spotify',
	zvuk: 'Zvuk (СберЗвук)',
	vk: 'VK music',
	ok: 'Одноклассники',
	yandex: 'Yandex music',
	deezer: 'Deezer',
	youtube: 'YouTube',
	tiktok: 'TikTok',
	facebook: 'Facebook',
	instagram: 'Instagram',
};

const ProductTypesGraph = ({ outlets }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.donut-chart.title'} />
			</h2>
			<div className={styles.graph}>
				<RadialChart
					data={outlets}
					colorType="literal"
					width={227}
					height={227}
					innerRadius={55}
					radius={100}
					showLabels={false}
				/>
				<div className={styles.labels}>
					{outlets.map((item) => (
						<div className={styles.label} key={item.outlet}>
							<div className={styles.leftSideLabel}>
								<span
									className={styles.labelColorLine}
									style={{ backgroundColor: item.color }}
								></span>
								<p className={styles.labelPercent}>{item.percentage}%</p>
							</div>

							<span className={styles.labelText}>
								{codeFormatted[item.outlet]}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default ProductTypesGraph;
