// Core
import debounce from 'lodash.debounce';
import lscache from 'lscache';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import SearchInput from './SearchInput/SearchInput';
import { Pagination } from 'material-design/components';
import { MainStatsReleases } from 'material-design/components/MainStats/MainStatsReleases';
import { Col, Container, Row } from 'react-grid-system';
import ContentEmpty from '../ContentEmpty/ContentEmpty';
import Banner from './Banner/Banner';
import { RepertoireFilterModalv2 } from 'material-design/modals/RepertoireFilterModalv2';

// Icons
import edit from 'images/edit.svg';
import { Cd } from 'material-design/svg';

// Utils
import { Loading, NoData } from 'components';
import { withAuth, withRoot, withUI } from 'hocs';
import { useOutsideClick } from 'material-design/hooks';
import { compose } from 'recompose';
import { accounts } from 'services';
import { setCache } from 'services/cacheHelper/setCache';
import {
	checkStatus,
	convertDataSale,
	getItemStatus,
	getItemStatusNew,
	getPerformers,
	getTypeTitle,
} from 'utils';
import { onEditReleaseHandler } from './utils/onEditReleaseHandler';
import { mapReleasesTypesToFilterSettings } from './data/mapReleasesTypesToFilterSettings';

// Data
import { filterReleaseSettingsData } from './data/filterSettingsData';
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';

// Styles
import s from './RepertoireReleaseSection.module.css';

const RepertoireReleaseSection = (props) => {
	const { statistics } = props;
	const {
		authContext: { accountId },
	} = props;
	const { currentReleaseStatus, materialsAvailableEditing } = useContext(
		UIContext
	);

	const history = useHistory();
	const lang = localStorage.getItem('lang');
	const prevFilters = JSON.parse(localStorage.getItem('prevFilters'));
	const prevStatus = JSON.parse(localStorage.getItem('prevStatus'));
	const prevPage = localStorage.getItem('prevPage');

	const resultRef = useRef(null);
	const helpRef = useRef(null);

	const [banner, setBanner] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [page, setPage] = useState(prevPage !== null ? +prevPage : 1);
	const [value, setValue] = useState('');
	const [total, setTotal] = useState(0);
	const [isFocus, setIsFocus] = useState(false);
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isReqLive] = useState(false);
	const [filterSettings, setFilterSettings] = useState(
		filterReleaseSettingsData
	);
	const [currentFilterSettings, setCurrentFilterSettings] = useState(
		prevFilters ? new Map(prevFilters) : new Map()
	);
	const [selectFilters, setSelectFilters] = useState(
		prevFilters ? new Map(prevFilters) : new Map()
	);
	const [releaseStatuses, setReleaseStatuses] = useState(
		prevStatus ?? [`status=${currentReleaseStatus}`]
	);
	const [releasesIDs, setReleasesIDs] = useState([]);
	const [availableEditing, setAvailableEditing] = useState(
		materialsAvailableEditing
	);

	const handleReceivedData = (res) => {
		res.data.forEach((item) => {
			item.status_item = getItemStatus(item.statuses);
			item.status_title = checkStatus(item.statuses);
			if (
				currentFilterSettings.size &&
				currentFilterSettings.has('release status')
			) {
				item.priority_status = currentFilterSettings.get('release status').code;
			} else {
				item.priority_status = getItemStatusNew(item.statuses);
			}

			if (item.sale_start_dates.length) {
				item.sale_start_dates.sort(
					(a, b) => convertDataSale(a.date) - convertDataSale(b.date)
				);
				item.sale_start_dates.forEach((itemDate) => {
					itemDate.date = itemDate.date.replace(
						/(\d{4})-(\d{2})-(\d{2})/,
						'$3.$2.$1'
					);
				});
			}
		});
		setTotal(res.total);
		setFilteredData(res.data);
	};

	const handleReceivedDraftFilterData = (res, status) => {
		if (res && Array.isArray(res.data) && res.data.length > 0) {
			res.data.forEach((item) => {
				item.priority_status = status;
			});
			setTotal(res.total);
			setFilteredData(res.data);
		} else {
			setTotal(0);
			setFilteredData([]);
		}
	};

	const onChange = (filter) => {
		setLoading(true);
		setValue(filter);
		getReleasesData(filter);
		setPage(1);
		localStorage.setItem('prevPage', 1);
	};

	const getReleasesData = (filter, page = prevPage) => {
		const searchByStatus = !!releaseStatuses.length;

		if (searchByStatus) {
			const statusTextItem = releaseStatuses.find((item) =>
				item.includes('status')
			);
			let statusText = '';
			if (statusTextItem) {
				statusText = statusTextItem.substring(
					statusTextItem.lastIndexOf('=') + 1
				);
			}

			if (['draft_processing', 'draft_verify', 'verify'].includes(statusText)) {
				filter.length < 3
					? accounts
							.getAssetsPageByStatus(
								accountId,
								'releases',
								page,
								releaseStatuses.join('&')
							)
							.then((res) => {
								res = res.data;
								handleReceivedDraftFilterData(res, statusText);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							})
					: accounts
							.getAssetsPageTextByStatus(
								accountId,
								filter,
								page,
								'releases',
								releaseStatuses.join('&')
							)
							.then((res) => {
								res = res.data;
								handleReceivedDraftFilterData(res, statusText);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
			} else {
				filter.length < 3
					? accounts
							.getReleasesPageByStatus(
								accountId,
								releaseStatuses.join('&'),
								page
							) // TODO QUERY
							.then((res) => {
								res = res.data;
								handleReceivedData(res);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							})
					: accounts
							.getReleasesPageTextByStatus(
								accountId,
								filter,
								releaseStatuses.join('&'),
								page
							)
							.then((res) => {
								res = res.data;
								handleReceivedData(res);
							})
							.catch((error) => {
								console.error('Error', error);
							})
							.finally(() => {
								setLoading(false);
							});
			}
			return;
		}
		filter.length < 3
			? accounts
					.getAssetsPage(accountId, 'releases', page)
					.then((res) => {
						res = res.data.data;
						handleReceivedData(res);
					})
					.catch((error) => {
						console.error('Error', error);
					})
					.finally(() => {
						setLoading(false);
					})
			: accounts
					.getAssetsPageText(accountId, filter, page)
					.then((res) => {
						res = res.data.data;
						handleReceivedData(res);
					})
					.catch((error) => {
						console.error('Error', error);
					})
					.finally(() => {
						setLoading(false);
					});
	};

	useEffect(() => {
		accounts.getFeatures().then((res) => {
			res = res.data.data;
			setAvailableEditing(res['feature.materials_available_editing']);
		});
	}, []);

	useEffect(() => {
		const releaseTypesAllCache = lscache.get('releaseTypesAllCache');
		if (
			releaseTypesAllCache &&
			!isReqLive &&
			releaseTypesAllCache.lang === lang
		) {
			setReleasesIDs(releaseTypesAllCache.data);
			setFilterSettings(
				mapReleasesTypesToFilterSettings(
					releaseTypesAllCache.data,
					filterSettings
				),
				false
			);
		} else {
			accounts
				.getReleaseTypes(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypesAll', 'releaseTypesAllCache', res);
					setReleasesIDs(res);
					setFilterSettings(
						mapReleasesTypesToFilterSettings(res, filterSettings, true)
					);
				})
				.catch((err) => {
					console.error('Error:', err);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		handleQueryParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFilterSettings]);

	const handleQueryParams = () => {
		const queryItems = [];
		for (let filterSetting of new Map(currentFilterSettings).values()) {
			queryItems.push(`${filterSetting.query}=${filterSetting.code}`);
		}
		if (queryItems.length) {
			setReleaseStatuses(queryItems);
			localStorage.setItem('prevStatus', JSON.stringify(queryItems));
		} else {
			setReleaseStatuses([]);
			localStorage.removeItem('prevStatus');
		}
	};

	useEffect(() => {
		setLoading(true);
		getReleasesData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseStatuses, value]);

	useEffect(() => {
		setLoading(true);
		getReleasesData(value, page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleFilter = useCallback(debounce(onChange, 500), [releaseStatuses]);

	const modalOpen = (active) => setModal(active);
	const handleChangeFilter = (data) => setFilterSettings(data);
	const handleCurrentFilter = (data) => {
		setCurrentFilterSettings(data);
	};

	const handleRemoveFilterItem = (settingName, fromModal = false) => {
		const newFilter = new Map(selectFilters);
		newFilter.delete(settingName);
		setSelectFilters(newFilter);
		localStorage.setItem(
			'prevFilters',
			JSON.stringify(Array.from(newFilter.entries()))
		);

		if (!fromModal) {
			handleCurrentFilter(newFilter);
			const newFilterSettings = filterSettings.map((filterSetting) => {
				if (filterSetting.title_en === settingName) {
					return {
						...filterSetting,
						list: filterSetting.list.map((item) => ({
							...item,
							checked: false,
						})),
					};
				}
				return filterSetting;
			});
			setFilterSettings(newFilterSettings);
		}
	};

	const handleClear = () => {
		const newFilterSettings = [...filterSettings];
		newFilterSettings.map((item) =>
			item.list.map((list_item) => (list_item.checked = false))
		);
		setCurrentFilterSettings(new Map());
		setFilterSettings(newFilterSettings);
		if (page > 1) {
			setPage(1);
			localStorage.setItem('prevPage', 1);
		}
		setSelectFilters(new Map());
		localStorage.removeItem('prevFilters');
		handleQueryParams();
		setModal(false);
	};

	const onClose = () => {
		setIsFocus(false);
	};

	useOutsideClick(resultRef, onClose, isFocus, helpRef);

	const changeStatusFromStatsBlock = (selectedStatus) => {
		if (selectedStatus === '') {
			handleClear();
			return;
		}

		const item = filterSettings[0];
		const settingsStats = [
			...item.list,
			{
				// TODO: RANDOM NUMBER THAT NOT EXIST
				id: 99,
				title_ru: pagesRu['rod.repertoire.draft_processing_or_verify'],
				title_en: pagesEn['rod.repertoire.draft_processing_or_verify'],
				code: 'verify',
				checked: false,
			},
		];

		const setting = settingsStats.find((obj) => obj.code === selectedStatus);

		item.list.map((obj) =>
			obj.id === setting.id ? (obj.checked = true) : (obj.checked = false)
		);

		const newCurrentFilter = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.title_en,
			name_ru: setting.title_ru,
			code: setting.code,
			show: true,
			query: item.query,
		};

		const newFilters = new Map(selectFilters).set(
			item.title_en,
			newCurrentFilter
		);

		setSelectFilters(newFilters);
		setPage(1);
		localStorage.setItem('prevPage', 1);
		localStorage.setItem(
			'prevFilters',
			JSON.stringify(Array.from(newFilters.entries()))
		);
		handleCurrentFilter(newFilters);
	};

	const handleSetFilters = (newFilter) => {
		const newFilters = new Map(selectFilters);
		newFilters.set(newFilter.title_en, newFilter);
		setSelectFilters(newFilters);
		localStorage.setItem(
			'prevFilters',
			JSON.stringify(Array.from(newFilters.entries()))
		);
	};

	useEffect(() => {
		if (currentReleaseStatus) {
			changeStatusFromStatsBlock('verify');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentReleaseStatus]);

	const {
		UIContext: { upload },
	} = props;

	return (
		<>
			{banner && <Banner setBanner={setBanner} title="rod.releases.title" />}
			<MainStatsReleases
				data={statistics}
				changeStatus={changeStatusFromStatsBlock}
			/>
			<SearchInput
				handleFilter={handleFilter}
				currentFilterSettings={currentFilterSettings}
				setIsFocus={setIsFocus}
				modalOpen={modalOpen}
				handleRemoveFilterItem={handleRemoveFilterItem}
				handleClear={handleClear}
			/>
			{loading ? (
				<Loading className={s.loader} />
			) : (
				<>
					{filteredData.length ? (
						<div className={s.repertoire__mainContent}>
							<div>
								<div
									className={`${s.repertoire__phonogramTitle} ${s.table} ${s.adaptive__songTitle}`}
								>
									<FormattedHTMLMessage id={'rod.for_all.title'} />
									<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
									<FormattedHTMLMessage id={'rod.for_all.performers'} />
									<FormattedHTMLMessage id={'rod.for_all.type'} />
									<FormattedHTMLMessage id={'rod.for_all.date'} />
									<FormattedHTMLMessage id={'rod.for_all.status'} />
								</div>
								<ul className={s.repertoire__phonogramItemsWrapper}>
									{filteredData.map((item) => (
										<li className={s.itemWrapper}>
											<Link
												key={item.id}
												to={`/repertoire/releases/${item.id}/info`}
											>
												<div
													className={
														item.priority_status === 'disputed' ||
														item.priority_status === 'not_ready' ||
														item.priority_status === 'rejected'
															? `${s.repertoire__phonogramItemError} ${s.adaptive__item}`
															: `${s.adaptive__item}`
													}
												>
													<Container fluid>
														<Row>
															<Col xs={4}>
																<div>
																	<Cd className={s.adaptive__songItemLogo} />
																</div>
															</Col>
															<Col xs={6}>
																<div className={s.adaptive__itemText}>
																	<span title={item.title}>{item.title}</span>
																</div>
															</Col>
															{item.moderation_status === 'moderation' &&
																availableEditing && (
																	<Col xs={2}>
																		<button
																			className={s.editIcon}
																			onClick={(e) =>
																				onEditReleaseHandler(
																					e,
																					item.id,
																					accounts,
																					history
																				)
																			}
																		>
																			<img src={edit} alt="" />
																		</button>
																	</Col>
																)}
														</Row>

														<Row>
															<Col xs={4}>
																<div className={s.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.subtitle'}
																	/>
																</div>
																<div className={s.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.performers'}
																	/>
																</div>
																<div className={s.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.type'}
																	/>
																</div>
																<div className={s.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.date'}
																	/>
																</div>
																<div className={s.adaptive__itemTitle}>
																	<FormattedHTMLMessage
																		id={'rod.for_all.status'}
																	/>
																</div>
															</Col>
															<Col xs={8}>
																<div className={s.adaptive__itemText}>
																	{item.subtitle ? (
																		<span title={item.subtitle}>
																			{item.subtitle}
																		</span>
																	) : (
																		<span>-</span>
																	)}
																</div>
																<div className={s.adaptive__itemText}>
																	{getPerformers(item)}
																</div>
																<div className={s.adaptive__itemText}>
																	<span
																		title={getTypeTitle(
																			item.release_type_id,
																			releasesIDs
																		)}
																	>
																		{getTypeTitle(
																			item.release_type_id,
																			releasesIDs
																		)}
																	</span>
																</div>
																<div className={s.adaptive__itemText}>
																	{item.sale_start_dates &&
																	item.sale_start_dates.length ? (
																		<span title={item.sale_start_dates[0].date}>
																			{item.sale_start_dates[0].date}
																		</span>
																	) : (
																		<span>-</span>
																	)}
																</div>
																<div className={s.adaptive__itemText}>
																	<span>
																		{item.statuses?.includes('draft') &&
																		selectFilters?.size === 0 ? (
																			<FormattedMessage
																				id={`rod.repertoire.draft`}
																			/>
																		) : (
																			<FormattedMessage
																				id={`rod.repertoire.${item.priority_status}`}
																			/>
																		)}
																	</span>
																</div>
															</Col>
														</Row>
													</Container>
												</div>

												<div
													className={
														item.priority_status === 'disputed' ||
														item.priority_status === 'not_ready' ||
														item.priority_status === 'rejected'
															? `${s.repertoire__phonogramItemError} ${s.table} ${s.adaptive__songTable}`
															: `${s.table} ${s.adaptive__songTable}`
													}
												>
													<div>
														<span title={item.title}>{item.title}</span>
													</div>
													<div>
														{item.subtitle ? (
															<span title={item.subtitle}>{item.subtitle}</span>
														) : (
															<span>-</span>
														)}
													</div>
													<div>{getPerformers(item)}</div>
													<div>
														<span
															title={getTypeTitle(
																item.release_type_id,
																releasesIDs
															)}
														>
															{getTypeTitle(item.release_type_id, releasesIDs)}
														</span>
													</div>
													<div>
														{item.sale_start_dates &&
														item.sale_start_dates.length ? (
															<span title={item.sale_start_dates[0].date}>
																{item.sale_start_dates[0].date}
															</span>
														) : (
															<span>-</span>
														)}
													</div>
													<div>
														<span>
															{item.moderation_status === 'moderation' &&
															availableEditing ? (
																<FormattedMessage id="rod.repertoire.draft_processing" />
															) : item.statuses?.includes('draft') &&
															  selectFilters?.size === 0 ? (
																<FormattedMessage id={`rod.repertoire.draft`} />
															) : (
																<FormattedMessage
																	id={`rod.repertoire.${item.priority_status}`}
																/>
															)}
														</span>
													</div>
													{item.moderation_status === 'moderation' &&
														availableEditing && (
															<button
																className={s.editIcon}
																onClick={(e) =>
																	onEditReleaseHandler(
																		e,
																		item.id,
																		accounts,
																		history
																	)
																}
															>
																<img src={edit} alt="" />
															</button>
														)}
													<Cd className={s.repertoire__phonogramItemLogo} />
												</div>
											</Link>
										</li>
									))}
								</ul>
							</div>

							{total > 10 && (
								<Pagination
									page={page}
									total={total}
									paginate={(pageNumber) => {
										setPage(pageNumber);
										localStorage.setItem('prevPage', pageNumber);
									}}
								/>
							)}
						</div>
					) : (
						<>
							{upload.releases && !value && <ContentEmpty release />}
							{upload.releases && value && (
								<NoData
									textId={'rod.filter.no_reports_title'}
									textIdNext={'rod.filter.no_reports_body'}
								/>
							)}
						</>
					)}

					<RepertoireFilterModalv2
						active={modal}
						setActive={modalOpen}
						filterData={filterSettings}
						setFilterData={handleChangeFilter}
						currentFilterSettings={currentFilterSettings}
						setCurrentFilter={handleCurrentFilter}
						selectFilters={selectFilters}
						setSelectFilters={handleSetFilters}
						setPage={setPage}
						removeSelectFilter={handleRemoveFilterItem}
						clearAll={handleClear}
					/>
				</>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(RepertoireReleaseSection);
