// Core
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import vkIcon from 'images/trends/topOutletsChart/vkIcon.svg';
import youtubeIcon from 'images/trends/topOutletsChart/youTubeIcon.svg';
import spotifyIcon from 'images/trends/topOutletsChart/spotifyIcon.svg';
import tiktokIcon from 'images/trends/topOutletsChart/tiktokIcon.svg';
import yandexIcon from 'images/trends/topOutletsChart/yandexIcon.svg';

// Styles
import styles from './TopOutletsGraph.module.css';

const data = [
	{
		code: 'youtube',
		angle: 0.125,
		label: '12,5%',
		color: '#FF7E7E',
		icon: youtubeIcon,
	},
	{ code: 'vk', angle: 0.125, label: '12,5%', color: '#3392FF', icon: vkIcon },
	{
		code: 'spotify',
		angle: 0.25,
		label: '25%',
		color: '#4BDF80',
		icon: spotifyIcon,
	},
	{
		code: 'tiktok',
		angle: 0.25,
		label: '25%',
		color: '#E374FD',
		icon: tiktokIcon,
	},
	{
		code: 'yandex',
		angle: 0.25,
		label: '25%',
		color: '#FDD533',
		icon: yandexIcon,
	},
];

const codeFormatted = {
	youtube: 'YouTube',
	vk: 'VK music',
	spotify: 'Spotify',
	tiktok: 'TikTok',
	yandex: 'Yandex music',
};

function ProductTypesGraph() {
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const centerX = canvas.width / 2;
		const centerY = canvas.height / 2;
		const outerRadius = 100;
		const innerRadius = outerRadius * 0.6;

		let startAngle = 0;

		data.forEach((item) => {
			const endAngle = startAngle + item.angle * 2 * Math.PI;

			// сегмент внешнего круга
			ctx.beginPath();
			ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
			ctx.lineTo(centerX, centerY);
			ctx.fillStyle = item.color;
			ctx.fill();
			ctx.closePath();

			// сегмент внутреннего круга
			ctx.beginPath();
			ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
			ctx.lineTo(centerX, centerY);
			ctx.fillStyle = '#FFFFFF'; // Цвет внутреннего круга
			ctx.fill();
			ctx.closePath();

			// Вычисляем положение иконки, отдаляем от центра на 4 пикселя
			const iconOffset = 4; // Смещение от центра
			const iconX =
				centerX +
				(outerRadius - 24 + iconOffset) * Math.cos((startAngle + endAngle) / 2);
			const iconY =
				centerY +
				(outerRadius - 24 + iconOffset) * Math.sin((startAngle + endAngle) / 2);

			// Загружаем иконку и рисуем ее
			const icon = new Image();
			icon.src = item.icon;
			icon.onload = () => {
				// Рисуем иконку размером 24x24, чтобы центрировать ее
				ctx.drawImage(icon, iconX - 12, iconY - 12, 24, 24); // Смещение на половину ширины и высоты
			};

			startAngle = endAngle; // Обновляем начальный угол
		});

		// Рисуем внутренний круг
		ctx.beginPath();
		ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
		ctx.fillStyle = '#FFFFFF'; // Цвет внутреннего круга
		ctx.fill();
		ctx.closePath();
	}, []);

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.donut-chart.title'} />
			</h2>
			<div className={styles.graph}>
				<canvas ref={canvasRef} width={227} height={227} />
				<div className={styles.labels}>
					{data.map((item) => (
						<div className={styles.label} key={item.code}>
							<div className={styles.leftSideLabel}>
								<span
									className={styles.labelColorLine}
									style={{ backgroundColor: item.color }}
								></span>
								<p className={styles.labelPercent}>{item.label}</p>
							</div>

							<span className={styles.labelText}>
								{codeFormatted[item.code]}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
export default ProductTypesGraph;
