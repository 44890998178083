// Core
import { FormattedMessage } from 'react-intl';

// UI
import CheckboxOutlets from './CheckboxOutlets/CheckboxOutlets';
import DataBar from 'containers/Modal/DistributionModals/DataBar/DataBar';

// Styles
import styles from './ReleaseDateBlock.module.css';

const ReleaseDateBlock = ({
	item,
	personal,
	setPersonal,
	outlets,
	selectOutletsDateCheckbox,
	changeSelectDateOutletsCheckbox,
	outletsList,
	errors,
}) => {
	return (
		<div className={styles.releaseDateBlock}>
			<DataBar
				item={item}
				modalType="distribution_date_edit"
				personal={personal}
				setPersonal={setPersonal}
			/>
			{Object.keys(errors).includes('delivery_start_time') && (
				<span className={styles.releaseDateBlockHelper}>
					{
						<FormattedMessage
							id={
								item.value === '7+'
									? 'rod.error.delivery_min_date_seven'
									: 'rod.error.delivery_min_date_one'
							}
						/>
					}
				</span>
			)}
			{Object.keys(errors).includes('sale_start_date') && (
				<span
					className={styles.releaseDateBlockHelper}
					style={
						Object.keys(errors).includes('delivery_start_time')
							? { marginTop: '10px' }
							: {}
					}
				>
					{
						<FormattedMessage
							id={
								item.value === '7+'
									? 'rod.error.min_date_seven'
									: 'rod.error.min_date_one'
							}
						/>
					}
				</span>
			)}
			{outlets?.length > 1 && (
				<CheckboxOutlets
					item={item}
					selectOutletsDateCheckbox={selectOutletsDateCheckbox}
					changeSelectDateOutletsCheckbox={changeSelectDateOutletsCheckbox}
					outlets={outlets}
					personal={personal}
					setPersonal={setPersonal}
				/>
			)}
			{selectOutletsDateCheckbox && personal.distribution_outlets?.length > 0 && (
				<div className={styles.outletsDataBar}>
					<DataBar
						item={item}
						outlets={outletsList}
						modalType="distribution_outlets_edit"
						personal={personal}
						setPersonal={setPersonal}
					/>
				</div>
			)}
		</div>
	);
};

export default ReleaseDateBlock;
